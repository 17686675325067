<script setup lang="ts">
import useIsDesktop from "~/composables/useIsDesktop";

const { isDesktop } = useIsDesktop()

const props = defineProps({
  isDefaultVersion: {
    type: Boolean,
    default: true,
  }
})
</script>

<template>
  <div class="header-contact">
    <ContactReinsurance :is-default-version="isDefaultVersion" />
    <div
      v-if="isDefaultVersion || (!isDefaultVersion && isDesktop)"
      class="opening-hours"
    >
      <span>{{ $t('header.contact.opening_days') }}&nbsp;</span>
      <span>{{ $t('header.contact.opening_hours') }}</span>
      <span>{{ $t('header.contact.weekend_opening_hours') }}</span>
    </div>
  </div>
</template>

<style lang="scss">
  .header-contact {
    font-size: 0.8rem;
    padding: $space-sm;
    display: flex;
    gap: $space-sm;
    flex-direction: column;

    @media (min-width: map-get($grid-breakpoints, xl)) {
      flex-direction: row;
      align-items: center;
      gap: 0;
      padding: 0;
    }

    @media (min-width: map-get($grid-breakpoints, xxl)) {
      font-size: 1rem;
    }

    .opening-hours {
      color: var(--color-grey-550);

      @media (min-width: map-get($grid-breakpoints, xl)) {
        border-left : 1px solid var(--color-navy-30);
        padding-left: 1em;
        margin-left: 1em;
      }

      > * {
        &:last-child {
          display: block;
        }

        @media (min-width: map-get($grid-breakpoints, xl)) {
          display: block;
        }
      }
    }
  }
</style>
